import { createSlice } from '@reduxjs/toolkit';

const LayerDataSlice = createSlice({
  name: 'layerData',
  initialState: {
    webAnalyticsPlacements: [],
    webAnalyticsPages: [],
    timeSeriesGeom: [],
    priorVisitsAnalysisAggregated: [],
    currentVisitsAnalysisAggregated: [],
    loadingPercentage: 0,
    isLoadingLayers: false,
    poiData: null,
    demographics: null,
    proximityAnalysis: [],
    clicksData: null,
    impressionsData: null,
    exposedVisitsData: null,
    unexposedVisitsData: null,
    exposedUpliftData: null,
    unexposedUpliftData: null,
    visitsAnalysisPriorData:  null,
    visitsAnalysisCurrentData: null,
    webAnalyticsData: null,
    locationBankData: null,
    brandLocationData: null,
    billboardData: null,
    poiLayerData: null,
    demographicsDisplayData: null
  },
  reducers: {
    setWebAnalyticsPlacements: (state, action) => {
      const currentSet = new Set(state.webAnalyticsPlacements);
      action.payload.forEach(item => currentSet.add(item));
      state.webAnalyticsPlacements = Array.from(currentSet);
    },
    setWebAnalyticsPages: (state, action) => {
      const currentSet = new Set(state.webAnalyticsPages);
      action.payload.forEach(item => currentSet.add(item));
      state.webAnalyticsPages = Array.from(currentSet);
    },
    setTimeSeriesGeom: (state, action) => {
      state.timeSeriesGeom = action.payload
    },
    setPriorVisitsAnalysisAggregated: (state,action) => {
      state.priorVisitsAnalysisAggregated = action.payload;
    },
    setCurrentVisitsAnalysisAggregated: (state,action) => {
      state.currentVisitsAnalysisAggregated = action.payload
    },
    setLoadingPercentage: (state,action) => {
      state.loadingPercentage = action.payload;
    },
    setIsLoadingLayers: (state, action) => {
      state.isLoadingLayers = action.payload;
    },
    setPOIData: (state, action) => {
      state.poiData = action.payload;
    },
    setDemographics: (state, action) => {
      state.demographics = action.payload;
    },
    setProximityAnalysis: (state, action) => {
      state.proximityAnalysis = action.payload;
    },
    setClicksData: (state, action) => {
      state.clicksData = action.payload;
    },
    setImpressionsData: (state, action) => {
      state.impressionsData = action.payload;
    },
    setExposedVisitsData: (state, action) => {
      state.exposedVisitsData = action.payload;
    },
    setUnexposedVisitsData: (state, action) => {
      state.unexposedVisitsData = action.payload;
    },
    setExposedUpliftData: (state, action) => {
      state.exposedUpliftData = action.payload;
    },
    setUnexposedUpliftData: (state, action) => {
      state.unexposedUpliftData = action.payload
    },
    setVisitsAnalysisPriorData: (state, action)  => {
      state.visitsAnalysisPriorData = action.payload;
    },
    setVisitsAnalysisCurrentData:  (state, action) =>  {
      state.visitsAnalysisCurrentData = action.payload;
    },
    setWebAnalyticsData: (state, action) =>  {
      state.webAnalyticsData = action.payload;
    },
    setLocationBankData: (state, action) => {
      state.locationBankData = action.payload;
    },
    setBrandLocationData: (state,action) => {
      state.brandLocationData  = action.payload;
    },
    setBillBoardData: (state, action) => {
      state.billboardData = action.payload;
    },
    setPOILayerData: (state,action) => {
      state.poiLayerData = action.payload;
    },
    setDemographicsDisplayData: (state, action) => {
      state.demographicsDisplayData = action.payload;
    }
  }
});

export default LayerDataSlice.reducer;
export const { 
  setWebAnalyticsPlacements, 
  setWebAnalyticsPages,
  setTimeSeriesGeom, setLoadingPercentage, setIsLoadingLayers, 
  setPriorVisitsAnalysisAggregated, 
  setCurrentVisitsAnalysisAggregated,
  setPOIData,
  setDemographics,
  setProximityAnalysis,
  setClicksData,
  setImpressionsData,
  setUnexposedVisitsData,
  setExposedVisitsData,
  setExposedUpliftData,
  setUnexposedUpliftData,
  setWebAnalyticsData,
  setVisitsAnalysisPriorData,
  setVisitsAnalysisCurrentData,
  setLocationBankData,
  setBrandLocationData,
  setBillBoardData,
  setPOILayerData,
  setDemographicsDisplayData
} = LayerDataSlice.actions;
